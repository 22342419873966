<template>
    <div>
        <sub-header :titles="subTitles" @refresh="refresh"></sub-header>
        <div class="content">
            <el-row style="height: 100%;display: flex;justify-content: center">
                <div class="ct-box">
                    <div class="info-box">
                        <div class="name">
                            {{ user.nickName }}
                            <i class="el-icon-edit" style="font-size: 20px; color: #0878ec; cursor: pointer;"
                                                   @click="editNickName"></i>
                        </div>
                        <div class="em-box">
                            <i class="el-icon-user-solid"></i>
                            {{ user.userName }}
                        </div>
                        <div v-if="user.roles && user.roles.length" class="rm-box">
                            {{ user.roles[0].roleName }}
                        </div>
                        <el-divider></el-divider>
                        <div>
                            <el-form :model="form" :rules="rules" ref="ruleForm">
                                <el-form-item label="パスワード" prop="pw">
                                    <el-input v-model="form.pw" type="passWord" placeholder="現在のパスワードを入力"></el-input>
                                </el-form-item>
                                <el-form-item label="新しいパスワード" prop="newpw">
                                    <el-input v-model="form.newpw" type="passWord" placeholder="新規登録のパスワードを入力"></el-input>
                                </el-form-item>
                                <el-form-item label="パスワードを確認" prop="newpw1">
                                    <el-input v-model="form.newpw1" type="passWord" placeholder="新しいパスワードを再び入力"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="text-box">* パスワードを忘れる場合、システム管理者に連絡し、パスワードをリセットする</div>
                        <div class="btn-box">
                            <el-button class="c-bk btn-width-big" type="primary" icon="el-icon-back"
                                       @click="callBack">キャンセル
                            </el-button>
                            <el-button class="s-bk btn-width-big" style="border: 0px;line-height: 10px !important;"
                                       type="primary"
                                       icon="el-icon-search"
                                       @click="save">保存</el-button>
                        </div>
                    </div>
                </div>
            </el-row>
        </div>
        <el-dialog :visible.sync="dialogVisible1" title="" :width="'600px'"  style="padding-bottom: 30px;"
                   :close-on-click-modal="false" :show-close="false">
            <span slot="title"><h2>ユーザー名を編集</h2></span>
            <el-form :model="form1" :rules="rules1" ref="form1" label-width="100px" style="width: 500px;">
                <el-form-item label="ユーザー名" prop="nickName">
                    <el-input v-model="form1.nickName" :show-word-limit="true" maxlength="10" ></el-input>
                </el-form-item>
            </el-form>
            <div class="btn-box" style="width: 100%">
                <el-button class="bk-1 btn-width-big" style="display: inline-block" @click="cancel" icon="el-icon-back">キャンセル
                </el-button>
                <el-button class="bk-1 btn-width-big" style="display: inline-block" @click="saveNickName" type="success" icon="el-icon-check">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import subHeader from '../components/SubHeader'
    import firstAdd from './components/supplier-add'

    export default {
        name: "supplier",
        components: {subHeader, firstAdd},
        data() {
            var validatePass = (rule, value, callback) => {
                if (this.form.newpw && this.form.newpw1 && this.form.newpw !==  this.form.newpw1) {
                    callback(new Error('現在のパスワードを入力'));
                } else {
                    callback();
                }
            };
            return {
                dialogVisible1: false,
                form1: {
                    nickName: ''
                },
                rules1: {
                    nickName: [
                        { required: true, message: 'ユーザー名を入力', trigger: 'blur' },
                    ],
                },
                subTitles: '',
                cz_types: '',
                types: '',
                user: {
                    userName: '',
                    nickName: '',
                    roles: []
                },
                tableHeight: 0,
                tableHeight1: 0,
                dialogVisible: false,
                form: {
                    pw: '',
                    newpw: '',
                    newpw1: ''
                },
                rules:{
                    pw: [
                        { required: true, message: '現在のパスワードを入力', trigger: 'blur' }
                    ],
                    newpw: [
                        { required: true, message: '新規登録のパスワードを入力', trigger: 'blur' }
                    ],
                    newpw1: [
                        { required: true, message: '新しいパスワードを再び入力', trigger: 'blur' },
                        { validator: validatePass, trigger: 'blur' }
                    ],
                }
            }
        },

        mounted() {
            this.subTitles = 'ユーザーを設定';
            this.user = this.$store.getters.user;
            this.getUserInfo();
        },
        methods: {
            refresh(){
                this.getUserInfo();
            },
            async getUserInfo(){
                let res = await this.$http.sys.getUser({userId: this.user.userId});
                if (res.code === 200) {
                    this.user.roles = res.data.roles;
                }
            },
            cancel(){
                this.dialogVisible1 = false;
            },
            editNickName() {
                this.form1.userId = this.user.userId;
                this.form1.nickName = this.user.nickName;
                this.form1.userName = this.user.userName;
                this.dialogVisible1 = true;
            },
            saveNickName(){
                let updateNickName = async () => {
                    let res = await this.$http.sys.userUpdate(this.form1);
                    if (res.code === 200) {
                        this.dialogVisible1 = false;
                        this.user.nickName = this.form1.nickName;
                        this.$store.dispatch("", this.user);
                        this.$message.success(res.msg)
                    }
                }
                this.$refs['form1'].validate((valid) => {
                    if (valid) {
                        updateNickName();
                    }
                })
            },
            edit() {
                this.types = 'edit';
                this.dialogVisible = true;
            },
            callBack() {
                this.$router.back()
            },
            save() {
                this.$refs['ruleForm'].validate(async (valid) => {
                    if (valid) {
                        // if (this.form.newpw1 !== this.form.newpw) {
                        //     // TODO 日本語で情報を提示する 現在パスワードが正しくない
                        //     this.$message.warning('現在のパスワードを入力')
                        //     return
                        // }
                        let res = await this.$http.sys.updatePwd({
                            oldPassword: this.form.pw,
                            newPassword: this.form.newpw
                        })
                        if (res.code == 200) {
                            this.$message.success(res.msg)
                        }
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
  * {
    -ms-overflow-style:none; /* Edge */
    scrollbar-width: none; /* Firefox */
  }
  ::-webkit-scrollbar {
    display: none; /* Chrome */
  }
  .content {
    box-shadow: 0px 0px 15px rgba(170, 170, 170, 35%);
    padding: 30px 30px;
    height: calc(100vh - 290px);
    overflow-y: scroll;
    background: rgba(255, 255, 255, 1);
    .ct-box {
      width: 330px;
      height: 100%;

      .info-box {
        text-align: left;

        .name {
          font-size: 34px;
          font-weight: bold;
          margin-top: 70px;
        }

        .em-box {
          margin-top: 10px;
        }

        .rm-box {
          margin-top: 10px;
          font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
          font-weight: 400;
          font-style: normal;
          color: #1890FF;
        }

        .item-box {
          .label-box {
            margin-top: 15px;
          }

          .input-box {
            margin-top: 8px;
          }
        }

        .text-box {
          margin-top: 40px;
          color: #d9011d;
        }

        .btn-box {
          margin-top: 50px;
          display: flex;
          justify-content: space-between;
          padding-bottom: 8px;
          button:nth-child(1) {
            background: #FFFFFF !important;
            color: #555555;
            border: 1px solid #e7e5e5 !important;
          }

          button:nth-child(2) {
            background: #66be96 !important;
          }
        }
      }
    }
  }

  /deep/ .el-header {
    padding: 0;
    box-shadow: rgba(170, 170, 170, 35%) 0px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .el-button {
    font-weight: bold;
    font-size: 20px;
    padding: 12px 12px;
    border-radius: 0px;
    border: 1px solid #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /deep/ .el-input__inner {
    border: 1px solid #f2f2f2;
  }

  /deep/ .el-dialog__footer {
    text-align: center;
  }

  /deep/ .el-dialog__header {
    text-align: left;
  }

  /deep/ .el-dialog__footer {
    padding-top: 0px;
    margin-top: -5px;
    padding-bottom: 30px;

    .el-button:nth-child(2) {
      background: rgba(102, 190, 150, 1);
    }
  }

  > > > .el-table {
    .el-table__body-wrapper {
      height: calc(100% - 48px) !important;
    }
  }

  .small-btn {
    padding: 5px 8px;
    color: white;

  }

  .small-btn:nth-child(2) {
    background: rgba(242, 242, 242, 0.2980392156862745);
    color: #409EFF;
  }
  .c-bk {
    font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
    font-size: 14px;
  }
  .s-bk {
    font-family:'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
    font-size: 14px;
  }
  .bk-1 {
    font-family:'PingFangSC-Regular', 'PingFang SC', sans-serif;
    font-size: 14px;
  }
  /deep/ .el-divider--horizontal {
    margin: 40px 0;
  }
</style>
